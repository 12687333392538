import "core-js/stable";

//window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import scrollspy from 'bootstrap/js/dist/scrollspy'

import css from './sass/app.sass';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('./img/', false, /\.(gif|png|jpe?g|svg)$/));



jQuery(function($) {

    /**
    * Smooth scrolling to page anchor on click
    **/
    $("a[href*='#']:not([href='#'])").click(function(e) {
        if (
            location.hostname == this.hostname
            && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            && !( $(this).attr('data-toggle') == 'tab' )
        ) {
            e.preventDefault();
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
            if ( anchor.length ) {
                $("html, body").animate( { scrollTop: anchor.offset().top + 10 }, 600);
            }
        }
    });

    

    function is_mobile() {
        if (window.innerWidth <= 992 ) {
            $("body").addClass("is-mobile");
        } else {
            $("body").removeClass("is-mobile");
        }
    }
    is_mobile();
    window.onresize = is_mobile;

    $("#menu-menu-principal .btn").first().before('<div class="break"></div>');


    $("form[data-action]").on('submit', function(e) {
        e.preventDefault();
        var form = $(this);
        var formId = $(this).attr('id');

        if( form.hasClass('submit') ) {
            return false;
        }

        var formData = new FormData(this);
        formData.append('action', form.attr('data-action'));
        form.addClass('submit');
        $('.form-group', form).removeClass('error');
        $('.text-danger', form).remove();

        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            dataType: 'json',
            success: function ( data ) {
                form.removeClass('submit');

                if(data.status == 200) {
                    form[0].reset();
                    var messageType = 'success';

                    $("#toasty_" + form.attr('data-action')).removeClass("alert-danger");
                    $("#toasty_" + form.attr('data-action')).addClass("alert-success");
                    $("#toasty_" + form.attr('data-action') + " .toast-body span.message").html(data.message);
                    $("#toasty_" + form.attr('data-action')).toast('show');

                } else {
                    var messageType = 'error';
                    $.each(data.errors, function(index, error){
                        var formGroup = $('#'+formId+' [name='+index+']').closest('.form-group, .form-check, .form-droppable, div');
                        formGroup.addClass('error');
                        formGroup.append('<div class="text-danger mt-1">' + error[0] + '</div>');
                    });
                    $("#toasty_" + form.attr('data-action')).removeClass("alert-success");
                    $("#toasty_" + form.attr('data-action')).addClass("alert-danger");
                    $("#toasty_" + form.attr('data-action') + " .toast-body span.message").html(data.message);
                    $("#toasty_" + form.attr('data-action')).toast('show');
                }
            },
            error: function () {
                alert("Une erreur inconnue s'est produite.");
                form.removeClass('submit');
            }
        });
    });

    var lastScrollTop = 0;
    var $navbar = $('#header');
    var positionTop = $(window).scrollTop();
    var hideToTop;

    function checkScroll(positionTop) {

        if (positionTop > 0) { // scroll down
            $navbar.addClass("top")
            $navbar.removeClass("bottom")
        } else if (positionTop==0) { // top
            $navbar.removeClass("top")
            $navbar.removeClass("bottom")
        } else { // scroll up
            $navbar.addClass("bottom")
            $navbar.removeClass("top")
        }

    }
    checkScroll(positionTop);
    $(window).scroll(function() {

        var st = $(this).scrollTop();

        if (st > lastScrollTop) { // scroll down
            $navbar.addClass("top")
            $navbar.removeClass("bottom")
        } else if (st==0) { // top
            $navbar.removeClass("top")
            $navbar.removeClass("bottom")
        } else { // scroll up
            $navbar.addClass("bottom")
            $navbar.removeClass("top")
        }

        lastScrollTop = st;
        
        clearTimeout(hideToTop);

        if( $(this).scrollTop() > 300 && $(this).scrollTop() < positionTop ) {
            $('#toTop').addClass('show');
            hideToTop = setTimeout(function() {
                $('#toTop').removeClass('show');
            }, 5000);
        } else {
            $('#toTop').removeClass('show')
        }

        positionTop = $(this).scrollTop();
    });

    //$(".block-intro .col-thin").css("margin-top", ($("#header").innerHeight()) + 'px');

    const nav = document.querySelector('#header');
    const menu = document.querySelector('#menu-menu-principal');
    const menuToggle = document.querySelector('.navbar-toggler');
    let isMenuOpen = false;
    
    
    // TOGGLE MENU ACTIVE STATE
    menuToggle.addEventListener('click', e => {
        e.preventDefault();
        isMenuOpen = !isMenuOpen;
        
        // toggle a11y attributes and active class
        menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
        menu.hidden = !isMenuOpen;
        nav.classList.toggle('menu-show');
        document.body.classList.toggle('menu-show');
        //navbar.classList.toggle('show');
    });
    
    
    // TRAP TAB INSIDE NAV WHEN OPEN
    nav.addEventListener('keydown', e => {
        // abort if menu isn't open or modifier keys are pressed
        if (!isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
            return;
        }
        
        // listen for tab press and move focus
        // if we're on either end of the navigation
        const menuLinks = menu.querySelectorAll('.nav-link');
        if (e.keyCode === 9) {
            if (e.shiftKey) {
                if (document.activeElement === menuLinks[0]) {
                    menuToggle.focus();
                    e.preventDefault();
                }
            } else if (document.activeElement === menuToggle) {
                menuLinks[0].focus();
                e.preventDefault();
            }
        }
    });

    $(".is-mobile #menu-menu-principal a").on("click", function(){
        nav.classList.toggle('menu-show');
        document.body.classList.toggle('menu-show');
        isMenuOpen = !isMenuOpen;
        // toggle a11y attributes and active class
        menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
    })

    if ($('.home').length > 0) {
        var scrollSpy = new scrollspy(document.body, {
            target: '#mainNavbar'
        })
    } else {
        $("#menu-menu-principal a[href*='#']:not([href='#']").each(function(){
            $(this).attr("href", '/' + $(this).attr("href"));
        })
    }
      
});